<template>
	<div :class="containerClass" @click="onWrapperClick">
        <AppTopBar @menu-toggle="onMenuToggle" />
        <div class="layout-sidebar" @click="onSidebarClick">
            <AppMenu  @menuitem-click="onMenuItemClick" :menus="menus"/>
        </div>

        <div class="layout-main-container">
			<ConfirmDialog></ConfirmDialog>
            <div :class="layoutMainClass">
                <router-view />
            </div>
			<!--
                <AppFooter />
			-->
        </div>
			<!--
			<AppConfig :layoutMode="layoutMode" @layout-change="onLayoutChange" />
            <transition name="layout-mask">
                <div class="layout-mask p-component-overlay" v-if="mobileMenuActive"></div>
            </transition>
			-->
	</div>
</template>

<script>
import AppTopBar from './components/topbar/AppTopbar.vue';
import AppMenu from './components/sidebar/AppMenu.vue';
//import SideBar from './components/sidebar/SideBar.vue'
//import AppConfig from './AppConfig.vue';
//import AppFooter from './AppFooter.vue';
import AuthService from '@/service/AuthService';
import menus from './menus';
import router from './router';

export default {
    emits: ['change-theme'],
    data() {
        return {
            layoutMode: 'static',
            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false,

        }
    },
    watch: {
        $route() {
            this.menuActive = false;
            this.$toast.removeAllGroups();
        }
    },
    methods: {
        onWrapperClick() {
            if (!this.menuClick) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }

            this.menuClick = false;
        },
        onMenuToggle() {
            this.menuClick = true;

            if (this.isDesktop()) {
                if (this.layoutMode === 'overlay') {
					if(this.mobileMenuActive === true) {
						this.overlayMenuActive = true;
					}

                    this.overlayMenuActive = !this.overlayMenuActive;
					this.mobileMenuActive = false;
                }
                else if (this.layoutMode === 'static') {
                    this.staticMenuInactive = !this.staticMenuInactive;
                }
            }
            else {
                this.mobileMenuActive = !this.mobileMenuActive;
            }

            event.preventDefault();
        },
        onSidebarClick() {
            this.menuClick = true;
        },
        onMenuItemClick(event) {
            if (event.item && !event.item.items) {
                this.overlayMenuActive = false;
                this.mobileMenuActive = false;
            }
        },
        onLayoutChange(layoutMode) {
            this.layoutMode = layoutMode;
        },
        addClass(element, className) {
            if (element.classList)
                element.classList.add(className);
            else
                element.className += ' ' + className;
        },
        removeClass(element, className) {
            if (element.classList)
                element.classList.remove(className);
            else
                element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        },
        isDesktop() {
            return window.innerWidth >= 992;
        },
        isSidebarVisible() {
            if (this.isDesktop()) {
                if (this.layoutMode === 'static')
                    return !this.staticMenuInactive;
                else if (this.layoutMode === 'overlay')
                    return this.overlayMenuActive;
            }

            return true;
        }
    },
    computed: {
        containerClass() {
            return ['layout-wrapper', {
                'layout-overlay': this.layoutMode === 'overlay',
                'layout-static': this.layoutMode === 'static',
                'layout-static-sidebar-inactive': this.staticMenuInactive && this.layoutMode === 'static',
                'layout-overlay-sidebar-active': this.overlayMenuActive && this.layoutMode === 'overlay',
                'layout-mobile-sidebar-active': this.mobileMenuActive,
				'p-input-filled': this.$primevue.config.inputStyle === 'filled',
				'p-ripple-disabled': this.$primevue.config.ripple === false
            }];
        },
        layoutMainClass() {
            return this.isDesktop() ? "layout-main" : "smalllayout-main";
        },
        logo() {
            return (this.$appState.darkTheme) ? "images/logo-white.svg" : "images/logo.svg";
        },
        menus() {
            const user = AuthService.currentUser;
            const routeMap = router.getRoutes().reduce((total, route) => {
                total[route.name] = route;
                return total;
            }, {});
            const filterMenus = function(menus) {
                return menus.reduce((total, menu) => {
                    if (menu.name) {
                        const route = routeMap[menu.name];
                        if (route && AuthService.authenticate(user, route)) {
                            total.push(menu)
                        }
                    }
                    if (Array.isArray(menu.items)) {
                        const items = filterMenus(menu.items);
                        if (items.length > 0) {
                            total.push({...menu,items});
                        }
                    }
                    return total;
                }, [])
            }
            const filteredMenus = filterMenus(menus);
            return filteredMenus;
        }
    },
    beforeUpdate() {
        if (this.mobileMenuActive)
            this.addClass(document.body, 'body-overflow-hidden');
        else
            this.removeClass(document.body, 'body-overflow-hidden');
    },
    components: {
        'AppTopBar': AppTopBar,
        'AppMenu': AppMenu,
        //'AppConfig': AppConfig,
        //'AppFooter': AppFooter,
    }
}
</script>

<style lang="scss">
@import './App.scss';
</style>
