<template>
  <div class="container">
    <router-view />
  </div>
</template>
<script>
export default {
    // mounted() {
    //   console.log("Mounted print");
    //   window.addEventListener("afterprint", this.onAfterPrint);
    // },
    // beforeUnmount() {
    //   window.removeEventListener("afterprint", this.onAfterPrint);
    // },
    // methods: {
    //   onAfterPrint(event, data) {
    //         console.log(event, data)
    //     }
    // }
}
</script>
<style type = "text/css">
.section {
  border: 1px solid #ced4da;
}
@media print {
  -webkit-print-color-adjust: exact;
  .footer {
    page-break-after: always;
  }
}
</style>
