const POUND2KG = 0.453592;
const isMobile = function () {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
};
const toBase64 = function (text) {
  const buff = new Buffer(text);
  const base64data = buff.toString("base64");
  return base64data;
};

const fromBase64 = function (base64data) {
  const buff = new Buffer(base64data, "base64");
  let text = buff.toString("ascii");
  return text;
};
const extractResource = function (qrCodeString) {
  if (qrCodeString) {
    const parts = qrCodeString.split("#/");
    if (Array.isArray(parts) && parts.length > 1) {
      const decodedString = fromBase64(parts[1]);
      if (decodedString) {
        return decodedString.split("|");
      }
    }
  }
};
const getImageBase = function () {
  return process.env.VUE_APP_API_BASE_URL + "/image/";
};
const isPositiveNumber = function (val) {
  let str = String(val);
  str = str.trim();
  if (!str) {
    return false;
  }
  str = str.replace(/^0+/, "") || "0";
  var n = Number(str);
  return n !== Infinity && n >= 0;
};
const converter = {
  tokg: function (lbs) {
    return isNaN(lbs) ? 0 : Math.round(lbs * POUND2KG * 100) / 100;
  },

  tolbs: function (val) {
    return isNaN(val) ? 0 : Math.round((val / POUND2KG) * 100) / 100;
  },

  formatLbsWeight(weightLbs) {
    if (!weightLbs || isNaN(weightLbs)) return "0 / 0";
    let kg = Math.round(weightLbs * POUND2KG * 100) / 100;
    return weightLbs.toString() + " / " + kg.toString();
  },
  length(value) {
    return Array.isArray(value) ? value.length : 0;
  },
};
const getCodeString = function (value, length, prefix) {
  let res = "",
    val = value,
    len = 0;
  while (val > 0 || len < length) {
    res = (val % 10) + res;
    len++;
    val = Math.floor(val / 10);
  }
  return prefix ? prefix + "-" + res : res;
};
export {
  converter,
  isMobile,
  toBase64,
  fromBase64,
  extractResource,
  getCodeString,
  getImageBase,
  isPositiveNumber,
};
