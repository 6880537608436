<template>
  <div class="layout-topbar">
    <router-link to="/" class="layout-topbar-logo">
      <!--
			<img alt="Logo" :src="topbarImage()" />
			-->
      <button
        class="p-link layout-menu-button layout-topbar-button"
        @click="onMenuToggle"
      >
        <i class="pi pi-bars"></i>
      </button>
      <span>Saigon Travel</span>
    </router-link>

    <button
      class="p-link layout-topbar-menu-button layout-topbar-button"
      v-styleclass="{
        selector: '@next',
        enterClass: 'hidden',
        enterActiveClass: 'scalein',
        leaveToClass: 'hidden',
        leaveActiveClass: 'fadeout',
        hideOnOutsideClick: true,
      }"
    >
      <i class="pi pi-ellipsis-v"></i>
    </button>
    <app-topbar-actions :user="user" />
  </div>
</template>

<script>
import { mapActions, useStore } from "vuex";
import { computed } from "vue";
//import AppNavbarActions from './AppNavbarActions';
import AppTopbarActions from "./AppTopbarActions";
export default {
  components: { AppTopbarActions },
  setup() {
    const store = useStore();
    const user = computed(() => store.state.auth.user);
    return {
      user,
    };
  },
  methods: {
    ...mapActions({
      logout: "auth/logout",
    }),
    onLogout() {
      this.logout();
      this.$router.push("/login");
    },
    onMenuToggle(event) {
      this.$emit("menu-toggle", event);
    },
    onTopbarMenuToggle(event) {
      this.$emit("topbar-menu-toggle", event);
    },
    topbarImage() {
      return this.$appState.darkTheme
        ? "images/logo-white.svg"
        : "images/logo-dark.svg";
    },
  },
  computed: {
    darkTheme() {
      return this.$appState.darkTheme;
    },
  },
};
</script>
