const menus = [
  /*
  {
    label: 'menu.home',
    items: [{
      label: 'Dashboard', icon: 'pi pi-fw pi-home', name: ''
    }]
  },
  */
  {
    label: "menu.admin",
    items: [
      {
        label: "menu.users",
        icon: "pi pi-fw pi-users",
        name: "admin_users",
        to: "/admin/users",
        class: "p-link",
      },
      {
        label: "menu.partner",
        icon: "pi pi-fw pi-briefcase",
        name: "admin_partner",
        to: "/admin/partner",
        class: "p-link",
      },
      {
        label: "menu.stores",
        icon: "pi pi-fw pi-database",
        name: "admin_stores",
        to: "/admin/stores",
        class: "p-link",
      },
      {
        label: "menu.manifest_item",
        icon: "pi pi-fw pi-database",
        name: "admin_manifestitem",
        to: "/admin/manifestitem",
        class: "p-link",
      },
      //{label: 'menu.configs', icon: 'pi pi-fw pi-cog', name: 'admin_configs', to: '/admin/configs', class: 'p-link'}
    ],
  },
  {
    label: "menu.receipt",
    items: [
      {
        label: "menu.receiptCreate",
        icon: "pi pi-fw pi-sitemap",
        name: "users_receipt_create",
        to: "/users/receipt/create",
        class: "p-link",
      },
      {
        label: "menu.receiptList",
        icon: "pi pi-fw pi-table",
        name: "users_receipt_list",
        to: "/users/receipt/list",
        class: "p-link",
      },
      {
        label: "menu.packageList",
        icon: "pi pi-fw pi-table",
        name: "agent_package_list",
        to: "/agent/package/list",
      },
    ],
  },
  {
    label: "menu.packing",
    visible: false,
    items: [
      {
        label: "menu.unpackedreceipt",
        icon: "pi pi-fw pi-sitemap",
        name: "agent_package_receipt",
        to: "/agent/package/receipt",
        class: "p-link",
      },
      {label: 'menu.packageCreate', icon: 'pi pi-fw pi-sitemap', name: 'agent_package_edit', to: '/agent/package/edit'},
      // {label: 'common.camera', icon: 'pi pi-fw pi-camera', name: 'agent_package_camera', to: '/agent/package/camera'}
    ],
  },
  {
    label: "menu.shipment",
    visible: true,
    items: [
      {
        label: "menu.shipmentCreate",
        icon: "pi pi-fw pi-sitemap",
        name: "agent_shipment_create",
        to: "/agent/shipment/create",
      },
      {
        label: "menu.shipmentList",
        icon: "pi pi-fw pi-box",
        name: "agent_shipment_list",
        to: "/agent/shipment/list",
      },
      // {
      //   label: "menu.shipmentScanner",
      //   icon: "pi pi-fw pi-server",
      //   name: "agent_shipment_scanner",
      //   to: "/agent/shipment/scanner",
      // },
    ],
  },
  {
    label: "menu.issue",
    visible: false,
    items: [
      {
        label: "menu.issueCreate",
        icon: "pi pi-fw pi-sitemap",
        name: "users_issue_edit",
        to: "/users/issue/edit",
        class: "p-link",
      },
      {
        label: "menu.issueList",
        icon: "pi pi-fw pi-sitemap",
        name: "users_issue_list",
        to: "/users/issue/list",
        class: "p-link",
      },
    ],
  },
  {
    label: "menu.warehouse",
    items: [
      {
        label: "menu.shipmentList",
        icon: "pi pi-fw pi-box",
        name: "users_shipment_list",
        to: "/users/shipment/list",
        class: "p-link",
      },
      // {
      //   label: "menu.arrivedPackages",
      //   icon: "pi pi-fw pi-table",
      //   name: "user_package_arrived",
      //   to: "/user/package/arrived",
      //   class: "p-link",
      // },
      //{label: 'menu.packageInfos', icon: 'pi pi-fw pi-info', name: 'user_package_infos', to: '/user/package/infos'},
      {
        label: "menu.warehouse_scanner",
        icon: "pi pi-fw pi-camera",
        name: "users_warehouse_import",
        to: "/users/shipment/import",
        class: "p-link",
      },
      {
        label: "menu.warehouse_export_list",
        icon: "pi pi-fw pi-box",
        name: "users_warehouse_export_list",
        to: "/users/warehouse/export/list",
        class: "p-link",
      },
      {
        label: "menu.warehouse_export_edit",
        icon: "pi pi-fw pi-pencil",
        name: "users_warehouse_export_edit",
        to: "/users/warehouse/export/edit",
        class: "p-link",
      },
      //{label: 'menu.warehouseShipping', icon: 'pi pi-fw pi-camera', name: 'user_camera', to: '/user/camera'}
    ],
  },
  {
    label: "menu.delivery",
    items: [
      {
        label: "menu.deliveryPackages",
        icon: "pi pi-fw pi-camera",
        name: "user_delivery_camera",
        to: "/users/delivery/camera",
        class: "p-link",
      },
    ],
  },
  {
    label: "menu.report",
    items: [],
  },
  {
    label: "menu.agent.admin",
    items: [
      {
        label: "menu.agent.user",
        icon: "pi pi-fw pi-users",
        name: "agent_user",
        to: "/agent/user",
        class: "p-link",
      },
      {
        label: "menu.address",
        icon: "pi pi-fw pi-id-card",
        items: [
          {
            label: "menu.address_imported",
            icon: "pi pi-fw pi-id-card",
            name: "agent_address_import",
            to: "/agent/address/import",
          },
          {
            label: "menu.address_active",
            icon: "pi pi-fw pi-id-card",
            name: "agent_address_list",
            to: "/agent/address/list",
          },
        ],
      },
    ],
  },
  {
    label: "menu.partner",
    items: [
      {
        label: "menu.receipt_list",
        icon: "pi pi-fw pi-table",
        name: "partner_receipt_list",
        to: "/partner/receipt/list",
        class: "p-link",
      },
      {
        label: "menu.receipt_create",
        icon: "pi pi-fw pi-id-card",
        name: "partner_receipt_create",
        to: "/partner/receipt/create",
        class: "p-link",
      },
    ],
  },
];
export default menus;
