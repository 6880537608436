<template>
  <Menu ref="menu" :model="actions" :popup="true" />
  <Button icon="pi pi-user" class="p-link" :label="user?.userFullName" @click="toggleMenu"/>
  <!--
  <button class="p-link layout-topbar-button" @click="toggleMenu">
    <i class="pi pi-user"></i>
    <span>{{user.userFullName}}</span>
  </button>
  -->
</template>

<script>
//import { mapActions } from 'vuex'
import AuthService from '@/service/AuthService'
export default {
  name: 'profile-section',
  props: {
    user: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data () {
    return {
      isShown: false,
      actions: [
        {
          label: this.$t('profile.setting'),
          icon: 'pi pi-cog',
          command: () => {
						this.openConfig();
					}
        },
        {
          label: this.$t('profile.calendar'),
          icon: 'pi pi-calendar',
          command: () => {
						this.openCalendar();
					}
        },
        {
          label: this.$t('profile.logout'),
          icon: 'pi pi-sign-out',
          command: () => {
						this.logout();
					}
        }
      ],
    }
  },
  methods: {
    /*
    ...mapActions({
      logout: 'auth/logout',
    }),
    */
    logout() {
      AuthService.logout().then(()=>{
        this.$router.push('/login')
      });
    },
    openConfig() {

    },
    openCalendar() {

    },
    toggleMenu(event) {
      this.$refs.menu.toggle(event);
    }
  }
}
</script>
