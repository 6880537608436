import http from './common'
import store from '../store';
class AuthService {
  login(user) {
    return http
      .post('/signin', {
        username: user.username,
        password: user.password,
      })
      .then((response) => {
        if (response.data.accessToken) {
          const base64Url = response.data.accessToken.split('.')[1]
          const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
          const jsonPayload = decodeURIComponent(
            atob(base64)
              .split('')
              .map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
              })
              .join(''),
          )
          const payload = JSON.parse(jsonPayload)
          const user = {
            accessToken: response.data.accessToken,
            sub: payload.sub,
            iat: payload.iat,
            exp: payload.exp,
            orgType: response.data.orgType,
            organization: response.data.organization,
            orgAddr: payload.orgAddr,
            orgPhone: payload.orgPhone,
            roles: response.data.roles,
            userFullName: response.data.userFullName
          }
          http.defaults.headers.common['Content-Type'] = 'application/json';
          http.defaults.headers.common['Authorization'] = 'Bearer ' + user.accessToken;
          store.dispatch('auth/updateUser', user);
          localStorage.setItem('user', JSON.stringify(user))
        }
        return response.data
      })
  }

  logout() {
    const user = this.currentUser;
    if (user) {
      return http
        .post('/signout')
        .then((response) => {
          if (response.data == 'successfull') {
            http.defaults.headers.common['Authorization'] = '';
            store.dispatch('auth/logout');
            localStorage.removeItem('user')
          }
          return response.data;
        }).catch((error)=>{
          store.dispatch('auth/logout');
          localStorage.removeItem('user');
          return error;
        })
    } else {
      return Promise.resolve("successfull")
    }
  }

  register(user) {
    return http.post('/signup', {
      username: user.username,
      email: user.email,
      password: user.password,
    })
  }

  authenticate(user, route) {
    if( !user || !route) {
      return false
    }
    let requiredRoles = route.meta.orgTypes ? route.meta.orgTypes[user.orgType] : null;
    if (requiredRoles) {
      return requiredRoles.indexOf('*') >= 0 || requiredRoles.indexOf(user.roles[0]) >= 0
    } else {
      return user.orgType == 'owner' && user.roles.indexOf('admin')>= 0 || route.meta.public
    }
  }
  get currentUser() {
    const storedUser = localStorage.getItem('user');
    let user = null
    if (typeof storedUser == 'string') {
      try {
        user = JSON.parse(storedUser)
        const currentTime = Math.round(new Date().getTime() / 1000)
        if (user.exp <= currentTime) {
          user = null;
          localStorage.removeItem('user');
        }
      } catch (e) {
        localStorage.removeItem('user')
        console.error(e)
      }
    }
    return user;
  }
}

export default new AuthService()
