import { createI18n } from 'vue-i18n'
const i18nConfig = {
  locale: 'en',
  legacy: true,
  fallbackLocale: 'vn',
  messages: {
    en: require('@/i18n/en.json'),
    vn: require('@/i18n/vn.json')
  }
}
const i18n = createI18n(i18nConfig);
export default i18n;
