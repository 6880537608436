import axios from "axios";
/*
const authHeader = function() {
  const user = JSON.parse(localStorage.getItem('user'))

  if (user && user.accessToken) {
    return {
      Authorization: 'Bearer ' + user.accessToken,
      'Content-type': 'application/json',
    }
  } else {
    return {
      'Content-type': 'application/json',
    }
  }
}
*/
const http = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL || "/api",
});
export default http;

class Service {
  constructor(resource) {
    this.resource = resource;
  }
  async filter(queries) {
    let first = true;
    let url = `/${this.resource}`;
    if (queries && typeof queries == "object") {
      for (let key in queries) {
        let value = queries[key];
        if (value != undefined && value != null) {
          let queryValue = encodeURIComponent(value);
          if (first) {
            url = `${url}?${key}=${queryValue}`;
            first = false;
          } else {
            url = `${url}&${key}=${queryValue}`;
          }
        }
      }
    }
    const response = await http.get(url);
    return response.data || [];
  }
  createQueryParams(options) {
    let tzOffset = new Date().getTimezoneOffset();
    let params = [
      `tzOffset=${tzOffset}`
    ];
    let page = options?.page || 0;
    params.push(`page=${page}`);
    
    if (options?.size != null) {
      params.push(`size=${options.size}`);
    }
    if (options?.offset) {
      params.push(`offset=${options.offset}`);
    }
    if (options?.sorts) {
      params.push(`sorts=${options.sorts}`);
      
    }
    if (options?.filter) {
      params.push(`filter=${options.filter}`);
    }
    
    return params;
  }
  async getAll(options) {
    let url = this.resource;
    let params = this.createQueryParams(options);
    
    if (params.length > 0) {
      url += '?' + params.join('&');
    }
    if (options?.country) {
      if (url == this.resource) {
        url = `${url}?country=${options.country}`;
      } else {
        url = `${url}&country=${options.country}`;
      }
    }
    const response = await http.get(url);
    if (response.status == 200) {
      return response.data;
    }
    return {
      content: [],
      totalElements: 0
    };
  }

  async get(id) {
    const response = await http.get(`/${this.resource}/${id}`);
    return response.status == 200 ? response.data : null;
  }
  async getByIds(ids, idField = "id") {
    const filter = {
      [idField]: {
        operator: "or",
        constraints: [
          {
            matchMode: "containedIn",
            value: ids
          }
        ]
      }
    };
    const options = {
      size: ids?.length,
      filter: encodeURIComponent(JSON.stringify(filter))
    };
    return await this.getAll(options);
  }
  save(data) {
    if (data && data.id) {
      return http
        .put(`/${this.resource}/${data.id}`, data)
        .then((response) => response.data);
    } else if (typeof data == "object") {
      return http
        .post(`/${this.resource}/`, data)
        .then((response) => response.data || {});
    } else {
      console.error("Content is empty");
    }
  }
  async create(data) {
    const response = await http.post(`/${this.resource}/`, data);
    return response.data || {};
  }

  async update(id, data) {
    if (!data) {
      data = id;
      id = data.id;
    }
    const response = await http.put(`/${this.resource}/${id}`, data);
    return response.data;
  }

  async updateField(id, field, newValue) {
    const response = await http.put(`/${this.resource}/${id}`, {
      [field]: newValue,
    });
    return response.data;
  }

  async delete(id) {
    return http.delete(`/${this.resource}/${id}`);
  }

  // async deleteAll() {
  //   return http.delete(`/${this.resource}`);
  // }
}

export { Service };
