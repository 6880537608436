//import AuthService from '../service/AuthService'
const storageUser = localStorage.getItem('user')
let user = null
if (typeof storageUser == 'string') {
  try {
    user = JSON.parse(storageUser)
    const currentTime = Math.round(new Date().getTime() / 1000)
    if (user.exp <= currentTime) {
      user = null
    }
  } catch (e) {
    localStorage.removeItem('user')
    console.error(e)
  }
}

const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null }

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    updateUser({commit}, userData) {
      commit('loginSuccess', userData)
    },
    logout({commit}) {
      commit('logout')
    }
    /*
    async login({ commit }, user) {
      try {
        const userRes = await AuthService.login(user)
        commit('loginSuccess', userRes)
        return Promise.resolve(userRes)
      } catch (error) {
        commit('loginFailure')
        return Promise.reject(error)
      }
    },
    logout({ commit }) {
      AuthService.logout()
      commit('logout')
      //router.push('/login')
    },
    */
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true
      state.user = user
    },
    loginFailure(state) {
      state.status.loggedIn = false
      state.user = null
    },
    logout(state) {
      state.status.loggedIn = false
      state.user = null
    },
    registerSuccess(state) {
      state.status.loggedIn = false
    },
    registerFailure(state) {
      state.status.loggedIn = false
    },
  },
}
