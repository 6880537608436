import html2canvas from 'html2canvas';
function openWindow (url, name, props) {
  let windowRef = null;
  // if (/*@cc_on!@*/false) { // for IE only
  //   windowRef = window.open('', name, props);
  //   windowRef.close();
  // }
  windowRef = window.open(url, name, props);
  if (!windowRef.opener) {
    windowRef.opener = self;
  }
  windowRef.focus();
  return windowRef;
}
function addStyles (win, styles) {
  styles.forEach(style => {
    let link = win.document.createElement('link');
    link.setAttribute('rel', 'stylesheet');
    link.setAttribute('type', 'text/css');
    link.setAttribute('href', style);
    win.document.getElementsByTagName('head')[0].appendChild(link);
  });
}
const VueHtmlToCanvas = {
  install (app, options = {}) {
    app.config.globalProperties.$htmlToCanvas = (location, localOptions, cb = () => true) => {
      let defaultName = '_blank',
        defaultSpecs = ['fullscreen=yes','titlebar=yes', 'scrollbars=yes'],
        defaultReplace = true,
        defaultStyles = [];
      let {
        name = defaultName,
        specs = defaultSpecs,
        replace = defaultReplace,
        styles = defaultStyles,
      } = options;

      // If has localOptions
      // TODO: improve logic
      if (localOptions) {
        if (localOptions.name) name = localOptions.name;
        if (localOptions.specs) specs = localOptions.specs;
        if (localOptions.replace) replace = localOptions.replace;
        if (localOptions.styles) styles = localOptions.styles;
      }

      specs = specs.length ? specs.join(',') : '';
      let win = null;
      if (location.element) {
        const element = window.document.getElementById(location.element);
        if (!element) {
          alert(`Element to print #${location.element} not found!`);
          return;
        }
        const url = '';
        win = openWindow(url, name, specs, replace);
        win.document.write(`
          <html>
            <head>
            </head>
            <body>
              ${element.innerHTML}
            </body>
          </html>
        `);
      } else if (location.url) {
        win = openWindow(location.url, name, specs, replace);
      }
      addStyles(win, styles);
      setTimeout(() => {
        html2canvas(win.document.body).then(function (canvas) {
          var doc = win.document;
          var src = canvas.toDataURL('image/png');
          //var img = doc.createElement('img');
          //img.src = src
          //doc.body.appendChild(img);
          doc.body.innerHTML = '<img src="' + src + '" alt="">';
          setTimeout(function () {
            win.print();
            //setTimeout(function () {win.close();}, 1);
            cb();
          }, 0);
        });
      }, 1000);

    }
  }
}

export default VueHtmlToCanvas;
