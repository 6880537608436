function addStyles (win, styles) {
  styles.forEach(style => {
    let link = win.document.createElement('link');
    link.setAttribute('rel', 'stylesheet');
    link.setAttribute('type', 'text/css');
    link.setAttribute('href', style);
    win.document.getElementsByTagName('head')[0].appendChild(link);
  });
}

function openWindow (url, name, props) {
  let windowRef = null;
  // if (/*@cc_on!@*/false) { // for IE only
  //   windowRef = window.open('', name, props);
  //   windowRef.close();
  // }
  windowRef = window.open(url, name, props);
  if (!windowRef.opener) {
    windowRef.opener = self;
  }
  windowRef.focus();
  return windowRef;
}

function repeat(times, sleep, fn) {
  setTimeout(() => {
    let res = fn();
    if (!res && times > 0) {
      repeat(times - 1, sleep, fn);
    }
  }, sleep);
}
function tryprint(win, cb = () => {}) {
  return () => {
    let loadContent = win.document.getElementById("__loaded__").innerText;
    if(loadContent == 'loaded') {
      win.focus();
      win.print();
      setTimeout(function () {win.close();}, 1);
      cb();
      return true;
    }
    return false;
  }
}
const VueHtmlToPaper = {
  install (app, options = {}) {
    app.config.globalProperties.$htmlToPaper = (location, localOptions, cb = () => true) => {
      let defaultName = '_blank',
        defaultSpecs = ['fullscreen=yes','titlebar=yes', 'scrollbars=yes'],
        defaultReplace = true,
        defaultStyles = [];
      let {
        name = defaultName,
        specs = defaultSpecs,
        replace = defaultReplace,
        styles = defaultStyles,
      } = options;

      // If has localOptions
      // TODO: improve logic
      if (localOptions) {
        if (localOptions.name) name = localOptions.name;
        if (localOptions.specs) specs = localOptions.specs;
        if (localOptions.replace) replace = localOptions.replace;
        if (localOptions.styles) styles = localOptions.styles;
      }

      specs = specs.length ? specs.join(',') : '';
      let win = null;
      //let win = null;
      if (location.element) {
        const element = window.document.getElementById(location.element);
        if (!element) {
          alert(`Element to print #${location.element} not found!`);
          return;
        }
        const url = '';
        win = openWindow(url, name, specs, replace);
        win.document.write(`
          <html>
            <head>
              <title>${window.document.title}</title>
            </head>
            <body>
              ${element.innerHTML}
            </body>
          </html>
        `);
      } else if (location.url) {
        const url = window.location.origin + '/' + location.url;
        win = openWindow(url, name, specs, replace);
      }
      if (win) {
        addStyles(win, styles);
        win.addEventListener('load', () => {
          repeat(10,1000,tryprint(win,cb));
          /*
          let times = 0;
          while(times < 10) {
            setTimeout(() => {
              let loadEl = win.document.getElementById("__loaded__").innerText;
              if(loadEl == 'loaded') {
                win.focus();
                win.print();
                setTimeout(function () {window.close();}, 1);
                cb();
                times = 10;
              } else {
                times++;
              }
            }, 1000);
          }
          */
        });
      }
      /*
      setTimeout(() => {
        //win.document.close();
        win.focus();
        win.print();
        setTimeout(function () {window.close();}, 1);
        cb();
      }, 3000);
      */
      return true;
    };
  },
};

export default VueHtmlToPaper;
