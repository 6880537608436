import axios from 'axios'
const http =  axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL || '/api'
});
class Service {
  constructor(resource) {
    this.resource = resource;
  }
  async filter(queries) {
    let first = true;
    let url=`/${this.resource}`;
    if (queries && typeof queries == 'object') {
      for (let key in queries) {
        let value = queries[key];
        if (value) {
          let queryValue = encodeURIComponent(value);
          if (first) {
            url=`${url}?${key}=${queryValue}`;
            first = false
          } else {
            url=`${url}&${key}=${queryValue}`;
          }
        }
      }
    }
    const response = await http.get(url);
    return response.data || [];
  }
  async getAll(options) {
    const page = options?.page || 0;
    let url=`/${this.resource}?page=${page}`;
    if (options && options.size) {
      url=`${url}&size=${options.size}`;
    }
    if (options && options.country) {
      url=`${url}&country=${options.country}`;
    }
    const response = await http.get(url);
    return response.data || [];
  }

  async get(id) {
    const response = await http.get(`/${this.resource}/${id}`);
    return response.data;
  }
  save(data) {
    if (data && data.id) {
      return http.put(`/${this.resource}/${data.id}`, data).then(response => response.data);
    } else if (typeof data == 'object') {
      return http.post(`/${this.resource}/`, data).then(response => response.data || {});
    } else {
      console.error("Content is empty");
    }
  }
  async create(data) {
    const response = await http.post(`/${this.resource}/`, data);
    return response.data || {};
  }

  async update(id, data) {
    if (!data) {
      data = id;
      id = data.id;
    }
    const response = await http.put(`/${this.resource}/${id}`, data);
    return response.data;
  }

  async updateField(id, field, newValue) {
    const response = await http.put(`/${this.resource}/${id}`, { [field]: newValue });
    return response.data;
  }

  delete(id) {
    return http.delete(`/${this.resource}/${id}`)
  }

  deleteAll() {
    return http.delete(`/${this.resource}`)
  }
}
const ApiService = {
  install (app) {
    app.config.globalProperties.$api = {
      createService: (resource) => {
        return new Service(resource)
      }
    }
  }
}
export default ApiService;
