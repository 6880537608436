<template>
  <ul class="layout-topbar-menu hidden lg:flex origin-top">
    <li>
      <ProfileDropdown :user="user"/>
    </li>
  </ul>
</template>
<script>
import ProfileDropdown from './dropdowns/ProfileDropdown'
export default {
  name: 'app-topbar-actions',
  components: {
    ProfileDropdown
  },
  props: {
    user: {
      type: Object,
      default() {
        return {}
      }
    },
    isTopBar: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
      onMenuToggle(event) {
          this.$emit('menu-toggle', event);
      },
		onTopbarMenuToggle(event) {
            this.$emit('topbar-menu-toggle', event);
        },
		topbarImage() {
			return this.$appState.darkTheme ? 'images/logo-white.svg' : 'images/logo-dark.svg';
		}
  },
	computed: {
		darkTheme() {
			return this.$appState.darkTheme;
		}
	}
}
</script>
